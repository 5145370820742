// const BASE_URL = "http://127.0.0.1:5000/";
// const BASE_URL = "https://api.wappss.com/";
// const BASE_URL = "https://api.wappss.com/";
// const BASE_URL = "https://api.infinitemlmsoftware.com/";
const DEFAULT_KEY = '14959'
// const BASE_URL = "http://192.168.9.47:5002/";
// const BASE_URL = "http://192.168.7.37:8000/node_api/";
//const BASE_URL = "http://162.19.146.132:1358/node_api/";

// live
const BASE_URL = "https://admincontrol.liveonplus.it/node_api/";



const PAYPAL_CLIENT_ID = "Ad077-Gx8stGcfWQYawBiQUc4UbKWIRIg_PK4lHL4pzHR-uOPMz2vCk_jQ8QU9pcEw0j8U3UYDrEUS75"

export { BASE_URL, DEFAULT_KEY, PAYPAL_CLIENT_ID }

