import React, { useState } from "react";
import TreeViewNavbar from "../../components/Tree/TreeViewNavbar";
import TreeViewContent from "../../components/Tree/tree-view/TreeViewContent";

const TreeView = () => {
  const [searchUsername, setSearchUsername] = useState("");
  const [showSearchLoader, setShowSearchLoader] = useState(false);
   const [selectedUserId, setSelectedUserId] = useState("");
  return (
    <>
      <TreeViewNavbar menu={"treeView"} setSearchUsername={setSearchUsername} setShowSearchLoader={setShowSearchLoader} setSelectedUserId={setSelectedUserId} searchUsername={searchUsername}/>
      <TreeViewContent searchUsername={searchUsername}
      setSearchUsername={setSearchUsername} setSelectedUserId={setSelectedUserId} selectedUserId={selectedUserId}
      />
    </>
  );
};

export default TreeView;
