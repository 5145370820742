import React, { useState } from "react";
import PayoutTiles from "../../components/Point/PayoutTiles";
import PayoutTable from "../../components/Point/PayoutTable";
import SubmitButton from "../../components/Common/buttons/SubmitButton";
import PayoutRequest from "../../components/Point/PayoutRequest";
import { ApiHook } from "../../hooks/apiHook";
import { useTranslation } from "react-i18next";

const PointHistory = () => {
  const { t } = useTranslation();
  const [showPayout, setShowPayout] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [activeTab, setActiveTab] = useState("point");
 
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [sortOptions, setSortOptions] = useState({
    sortBy: "",
    direction: ""
  })
const [dateRange, setDateRange] = useState({ startDate: "", endDate: "" });

  const handlePayout = () => {
    setShowPayout((prevShowPayout) => !prevShowPayout);
  };

  //--------------------------------- API --------------------------------
  // const payoutDetails = ApiHook.CallPayoutDetails(
  //   currentPage,
  //   itemsPerPage,
  //   activeTabValues.map(item => `"${item}"`),
  //   sortOptions.sortBy,
  //   sortOptions.direction,
  // );
  const requestDetails = ApiHook.CallGetPvHistory(
    currentPage,
    itemsPerPage,
    activeTab,
    dateRange?.startDate,
    dateRange?.endDate,
    sortOptions.sortBy,
    sortOptions.direction,

  );
  const tiles = ApiHook.CallGetPvTiles();

  





  return (
    <>
      <div className="page_head_top">{t("Points")}</div>
      <div className="ewallet_top_btn_sec">
        <div className="row justify-content-between">
          <div className="col-md-4"></div>
          <div className="col-md-4 text-end ">
            <div className="dropdown btn-group top_right_pop_btn_position">
              {requestDetails?.data?.isUserPayoutEnabled &&
                <SubmitButton
                  isSubmitting=""
                  click={handlePayout}
                  text={"payoutRequest"}
                  className="top_righ_pop_btn"
                />
              }
            </div>
          </div>
        </div>
      </div>
      <PayoutTiles
        percentage={tiles?.data?.tilePercentages}
        tiles={tiles?.data}
      />
      <PayoutTable
        data={requestDetails?.data}
        type={"point"}
        setActiveTab={setActiveTab}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        activeTab={activeTab}
        setSortOptions={setSortOptions}
        setDateRange={setDateRange} 
      />

    </>
  );
};

export default PointHistory;
