import API from "../../api/api";

const callApi = async (endpoint) => {
    try {
        const response = await API.get(endpoint);
        if (response.status === 200) {
            return response?.data?.data;
        } else {
            return response?.data?.data;
        }
    } catch (error) {
        console.log(error);
        throw error;
    }
};

export const PointService = {
    // callDetails: async (page, perPage, type, sortBy, direction) => {
    //     return callApi(`payout-details?page=${page}&pageSize=${perPage}&status=[${type}]&sortBy=${sortBy}&direction=${direction}`);
    // },
    callPointApi: async (page, itemsPerPage, selectedCategory, startDate, endDate, sortBy, direction) => {
        console.log(page, itemsPerPage, selectedCategory, startDate, endDate, sortBy, direction)
        return callApi(`pv_histories?page=${page}&perPage=${itemsPerPage}&startDate=${startDate}&endDate=${endDate}&type=${selectedCategory}&sortBy=${sortBy}&direction=${direction}`)
    },
    GetPotentailEarnings: async (page, itemsPerPage, selectedCategory, startDate, endDate, sortBy, direction) => {
        return callApi(`potential_eranings?page=${page}&perPage=${itemsPerPage}&startDate=${startDate}&endDate=${endDate}&type=${selectedCategory}&sortBy=${sortBy}&direction=${direction}`)
    },
     callTilesApi: async () => {
        return callApi('pv_tiles')
    },
      callRankAdvancment: async (id) => {
        return callApi(`rank_advancement?rank_id=${id}`)
    },
    callRanks: async () => {
        return callApi('all_ranks')
    },
    
}