import React from "react";
import TableMain from "../Common/table/TableMain";
import PointTableFilter from "../Common/table/PointTableFilter";
import { getLastPage } from "../../utils/getLastPage";
import { useTranslation } from "react-i18next";

const PayoutTable = (props) => {
  const { t } = useTranslation();
  const reportHeader = [t("date"), t("amount"), t("payout_method"), t("status")]; 
  const headers = [t("username"), t("fromusername"), t("fromname"), t("personalpoints"),t("teampoints"),t("ptaPoints"),t("Date"),t("sourceofpoints")];
  const lastPage = getLastPage(props.itemsPerPage, props?.data?.totalCount);


   
  return (
    <div className="ewallet_table_section">
      <div className="ewallet_table_section_cnt">
        <PointTableFilter
          setActiveTab={props.setActiveTab}
          activeTab={props.activeTab}
          headers={headers}
          reportHeader={reportHeader}
          data={props.data?.data}
          type={props.type}
          setCurrentPage={props.setCurrentPage}
          setDateRange={props.setDateRange}
        />
        <div className="table-responsive min-hieght-table">
          <TableMain
            headers={headers}
            data={props?.data?.data}
            startPage={1}
            currentPage={props.currentPage}
            totalPages={lastPage}
            type={props.type}
            itemsPerPage={props.itemsPerPage}
            setItemsPerPage={props.setItemsPerPage}
            setCurrentPage={props.setCurrentPage}
            setEpinSortOptions={props.setSortOptions}
          />
        </div>
      </div>
    </div>
  );
};

export default PayoutTable;
