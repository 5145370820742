import React, { useState } from "react";

import { ApiHook } from "../../hooks/apiHook";
import { useTranslation } from "react-i18next";
import Approval from "../../components/Approval/Approval";
import { toast } from "react-toastify";



const ApprovalHistory = () => {
  const { t } = useTranslation();
  const [showPayout, setShowPayout] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [activeTab, setActiveTab] = useState(true);
 
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [sortOptions, setSortOptions] = useState({
    sortBy: "",
    direction: ""
  })
const [dateRange, setDateRange] = useState({ startDate: "", endDate: "" });
const  [selectedValues, setSelectedValue] = useState({});

 const handleSelectChange = (selectedOption,rowId) => {
  setSelectedValue((prevValues) => ({
      ...prevValues,
      [rowId]: selectedOption, // Update the selected option for this row
    }));
   
  };

  //--------------------------------- API --------------------------------
 
  const pendingDetails = ApiHook.CallPendingRegister(
  activeTab,
  setActiveTab
  );
   const passwordMutation = ApiHook.CallPendingRegisterUser();

  const handlesubmit = (mainData) =>{
   const  selectedValue = selectedValues[mainData.id]
    mainData = {...mainData,selectedValue}

      passwordMutation.mutate(
     mainData,
      {
        onSuccess: (res) => {
          console.log(res)
          if (res.status) {
            toast.success(res.data.message);
            setActiveTab(true)
          } else {
            toast.error(res?.data?.description);
          }    
        },
         onError: (err) => {
         console.log("err",err)
      },
      }
    );
  }


  

  





  return (
    <>
      <div className="page_head_top">{t("allocate sponsor")}</div>
      <div className="ewallet_top_btn_sec">
        <div className="row justify-content-between">
          <div className="col-md-4"></div>
          <div className="col-md-4 text-end ">
            <div className="dropdown btn-group top_right_pop_btn_position">
              {/* {requestDetails?.data?.isUserPayoutEnabled &&
                <SubmitButton
                  isSubmitting=""
                  click={handlePayout}
                  text={"payoutRequest"}
                  className="top_righ_pop_btn"
                />
              } */}
            </div>
          </div>
        </div>
      </div>
      {/* <PayoutTiles
        percentage={tiles?.data?.tilePercentages}
        tiles={tiles?.data}
      /> */}
      <Approval
        data={pendingDetails?.data}
        type={"approval"}
        setActiveTab={setActiveTab}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        activeTab={activeTab}
        setSortOptions={setSortOptions}
        setDateRange={setDateRange} 
        handleSelectChange={handleSelectChange}
        selectedValues={selectedValues}
        handlesubmit={handlesubmit}
      />

    </>
  );
};

export default ApprovalHistory;
