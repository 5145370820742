
import { PointService } from "../../services/points/points";

export const GetPvHistory = async (page, itemsPerPage, selectedCategory, startDate, endDate, sortBy, direction) => {
    try {
        const response = await PointService.callPointApi(page, itemsPerPage, selectedCategory, startDate, endDate, sortBy, direction);
        return response
    } catch (error) {
        console.log(error);
    }
};


export const GetPotentailEarnings = async (page, itemsPerPage, selectedCategory, startDate, endDate, sortBy, direction) => {
    try {
        const response = await PointService.GetPotentailEarnings(page, itemsPerPage, selectedCategory, startDate, endDate, sortBy, direction);
        return response
    } catch (error) {
        console.log(error);
    }
};



export const GetPvTiles  =  async(data) =>{
    try {
        const response = await PointService.callTilesApi(data);
        return response
    } catch (error) {
        console.log(error);
    }
}
export const GetRankAdvancment = async(data)=>{
    try {
        const response = await PointService.callRankAdvancment(data);
        return response
    } catch (error) {
        console.log(error);
    }

}
export const GetAlltheRanks = async(data)=>{
    try {
        const response = await PointService.callRanks(data);
        return response
    } catch (error) {
        console.log(error);
    }

}
