import React, { useState } from "react";
import SubmitButton from "../buttons/SubmitButton";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;

const PointTableFilter = ({
  type,
  setActiveTab,
  selectedCategory,
  setSelectedCategory,
  setDateRange,
  category,
  setCurrentPage,
}) => {
  const categoryList = [];
  const listOptions = [];
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState(selectedCategory);
  const [dateKey, setDateKey] = useState(Date.now());
  const [date, setDate] = useState({ startDate: "", endDate: "" });
  const transferCategory = [
    { label: t("credit"), value: "credit" },
    { label: t("debit"), value: "debit" },
  ];
  // Adding lang to the category
  category?.map((item) => {
    const data = {
      label: t(`${item.label}`),
      value: item.value,
    };
    listOptions.push(data);
  });

  const handleTabChange = (e) => {
    console.log(setDateRange);
    setDateRange({
      startDate: date.startDate,
      endDate: date.endDate,
    });
    setActiveTab("point");
    if (e.target.id === "searchButton") {
      // setCurrentPage(1);
      // if (type === "transfer_history") {
      //   setApiTab(type);
      // } else if (type === "my_earnings") {
      //   setApiTab(type);
      // }
    } else if (e.target.id === "resetButton") {
      setCurrentPage(1);
      setDate({ startDate: "", endDate: "" });
      setDateRange({ startDate: "", endDate: "" });
      setActiveTab("point");

    }
  };

  const handleSelectChange = (values) => {
    setSelectedValue(values);
  };

  const handleDateRange = (dates) => {
    if (Array.isArray(dates) && dates.length === 2) {
      const [toDate, fromDate] = dates;
      setDate({
        startDate: toDate.format("YYYY-MM-DD"),
        endDate: fromDate ? fromDate.format("YYYY-MM-DD") : null,
      });
    }
  };
  return (
    <div className="filter_Section">
      <div className="row">
        <div key={"1"} className="col-xl-2 col-md-3">
          <div className="right_search_div">
            <label className="la-control">{t("date")}</label>
            <RangePicker
              key={dateKey}
              onChange={(dates) => handleDateRange(dates)}
            />
          </div>
        </div>
        {!!(type === "transfer_history") && (
          <div key={"2"} className="col-xl-2 col-md-3">
            <div className="right_search_div">
              <label className="la-control">{t("category")}</label>
              <Select
                options={transferCategory}
                value={selectedValue}
                onChange={handleSelectChange}
                isMulti
              />
            </div>
          </div>
        )}
       
          {/* <div key={"2"} className="col-xl-3 col-md-3">
            <div className="right_search_div">
              <label className="la-control">{t("userName")}</label>
              <Select
                options={listOptions}
                value={selectedValue}
                onChange={handleSelectChange}
                isMulti
              />
            </div>
          </div> */}
        
        <div className="col-xl-2 col-md-3 mt-4">
          <div className="right_search_div d-flex gap-1">
            <SubmitButton
              isSubmitting=""
              text="search"
              className="btn btn-primary"
              click={handleTabChange}
              id="searchButton"
            />
            <SubmitButton
              isSubmitting=""
              text="reset"
              className="btn btn-secondary"
              click={handleTabChange}
              id="resetButton"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PointTableFilter;
