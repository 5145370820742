import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CurrencyConverter from "../../Currency/CurrencyConverter";
import { useSelector } from "react-redux";

import { styled } from "@mui/material/styles";
import { Tab, Tabs } from "@mui/material";
import { Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

const RankTiles = (props) => {
  const { t } = useTranslation();
  const userSelectedCurrency = useSelector(
    (state) => state?.user?.selectedCurrency
  );
  const conversionFactor = useSelector(
    (state) => state?.user?.conversionFactor
  );
  const [value, setValue] = useState(0);

  const [isTabChanging, setIsTabChanging] = useState(false);
  const [selectedTab, setSelectedTab] = React.useState(0);

  const handleChange = (event, newValue) => {
    setIsTabChanging(true);
    setTimeout(() => {
      setIsTabChanging(false);
    }, 400);
    setSelectedTab(newValue);
    props.onSelectRank(props?.ranks?.data[newValue]?.id)
  };
  
  const StyledTabs = styled((props) => (
    <Tabs
      variant="scrollable"
      scrollButtons="auto"
      aria-label="scrollable auto tabs example"
      {...props}
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />,
      }}
    />
  ))({
    "& .MuiTabs-indicator": {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
    },
    "& .MuiTabs-indicatorSpan": {
      maxWidth: 60,
      width: "100%",
      // backgroundColor: '#635ee7',
    },
  });

  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: "none",
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(15),
      marginRight: theme.spacing(1),

      color: "#00090",
      background: "#E5E5E550",
      "&.Mui-selected": {
        color: "rgb(88, 147, 241)",
        backgroundColor: "#ffffff",
      },
      "&.Mui-focusVisible": {
        backgroundColor: "rgba(100, 95, 228, 0.32)",
      },
    })
  );

  return (
    <div className="ewallet_top_cnt_sction banner_content">
      <div className="row">
        {console.log(props)}
        <StyledTabs
          value={selectedTab}
          onChange={handleChange}
          aria-label="styled tabs example"
          style={{
            opacity: isTabChanging ? 0 : 1, // Default to 1 when not changing
            transition: "opacity 0.3s ease",
            padding: "10px", 
          }}
        >
          {props && props?.ranks?.data?.map((item) => (
            <StyledTab label={item?.name} />
          ))}
        </StyledTabs>
      </div>
    </div>
  );
};

export default RankTiles;
