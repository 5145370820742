import React, { useState } from "react";
import RankTiles from "../../components/Rank/RankTiles";
import { ApiHook } from "../../hooks/apiHook";
import { useTranslation } from "react-i18next";
import ProgressBar from "react-bootstrap/ProgressBar";

const RankHistory = () => {
  const { t } = useTranslation();
  const [showPayout, setShowPayout] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [activeTab, setActiveTab] = useState([
    { value: "paid", label: `${t("paid")}` },
    { value: "requested", label: `${t("requested")}` },
    { value: "approved", label: `${t("approved")}` },
    { value: "rejected", label: `${t("rejected")}` },
  ]);
  const activeTabValues = activeTab.map((item) => item.value);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [sortOptions, setSortOptions] = useState({
    sortBy: "",
    direction: "",
  });

  const handlePayout = () => {
    setShowPayout((prevShowPayout) => !prevShowPayout);
  };

  //--------------------------------- API --------------------------------
  // const payoutDetails = ApiHook.CallPayoutDetails(
  //   currentPage,
  //   itemsPerPage,
  //   activeTabValues.map((item) => `"${item}"`),
  //   sortOptions.sortBy,
  //   sortOptions.direction
  // );
  const [selectedTab, setSelectedTab] = useState(1);
  const [setrank, setrankCall] = useState(true);

  const ranks = ApiHook.CallRanks();

  const rankdvancment = ApiHook.CallRankAdvacncment(
    selectedTab,
    setrank,
    setrankCall
  );
  const variants = ["info", "warning", "danger", "success"];
    const [isChecked, setIsChecked] = useState("entrepreneurialDifference");

  function getRandomColor() {
    const randomIndex = Math.floor(Math.random() * variants.length);
    return variants[randomIndex];
  }

  const onSelectRank = (id) => {
    setSelectedTab(id);
    setrankCall(true);
    setIsChecked("entrepreneurialDifference")
  };
  

  // Handler for when checkbox is clicked/changed
  const handleCheckboxChange = (event) => {
    if(event.target.checked){
       setIsChecked(event.target.value);
    }
  };


  return (
    <>
      <div className="page_head_top">{t("rank-advancment")}</div>
      <div className="ewallet_top_btn_sec">
        <div className="row justify-content-between">
          <div className="col-md-4"></div>
          <div className="col-md-4 text-end "></div>
        </div>
      </div>

      <div className="ewallet_table_section">
        <div className="ewallet_table_section_cnt">
          {ranks && <RankTiles ranks={ranks} onSelectRank={onSelectRank} />}
          <div className="table-responsive min-hieght-table rank_adv_div">
            <div className="row ">
              <div className="col-6 border-right justify-content-center">
                <div className="teammbrs_rank_progressbar">
                  <div className="teammbrs_rank_label">
                    <p className="text-center">{rankdvancment?.data?.name}</p>
                    <div className="teammbrs_rank_achievements  "></div>
                  </div>

                  
                   {rankdvancment?.data?.criteria.map((critObj, index) => (
                    <div className="teammbrs_rank_progress" key={index}>
                      <p>{t(critObj.name)}</p>
                      <ProgressBar
                        variant={getRandomColor()}
                        label={`${
                          critObj.achieved > critObj.goal
                            ? parseFloat(critObj.goal / critObj.goal) * 100
                            : (
                                parseFloat(critObj.achieved / critObj.goal) *
                                100
                              ).toFixed(2)
                        } % `}
                        striped={true}
                        now={
                          critObj.achieved > critObj.goal
                            ? parseFloat(critObj.goal / critObj.goal) * 100
                            : parseFloat(critObj.achieved / critObj.goal) * 100
                        }
                        animated={true}
                        max={100}
                      />
                    </div>
                  ))}
                  {rankdvancment?.data?.keyCretieria[isChecked]?.map((critObj, index) => (
                    <div className="teammbrs_rank_progress" key={index}>
                      <p>{t(critObj.name)}</p>
                      <ProgressBar
                        variant={getRandomColor()}
                        label={`${
                          critObj.achieved > critObj.goal
                            ? parseFloat(critObj.goal / critObj.goal) * 100
                            : (
                                parseFloat(critObj.achieved / critObj.goal) *
                                100
                              ).toFixed(2)
                        } % `}
                        striped={true}
                        now={
                          critObj.achieved > critObj.goal
                            ? parseFloat(critObj.goal / critObj.goal) * 100
                            : parseFloat(critObj.achieved / critObj.goal) * 100
                        }
                        animated={true}
                        max={100}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-6">
                <div className="teammbrs_rank_progressbar">
                  <div className="teammbrs_rank_label">
                    <p className="text-center">Benefits</p>
                    <div className="teammbrs_rank_achievements"></div>
                  </div>
                  {rankdvancment?.data?.benefits.map((critObj, index) => (
                    <div className="teammbrs_rank_progress" key={index}>
                      <ul>
                        <input type="checkbox" id="check3"  value={critObj.name} checked={critObj.name==isChecked}  name={critObj.name}    onChange={handleCheckboxChange}/> {t(critObj.name)}
                        {/* <li>{t(critObj.name)} </li> */}
                      </ul>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RankHistory;
